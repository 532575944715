<template>
    <div>
        <div>
            <BannerSliderMobile :areas="$wdconfig.pages.home.banners.layout === 'multiple' ? [2001, 2002] : [2001]" class="d-block d-lg-none"/>
            <div class="d-none d-lg-block">
                <div v-if="$wdconfig.pages.home.banners.layout === 'single'">
                    <SingleBannerSlider :banners="banners[2001]"/>
                </div>
                <div v-if="$wdconfig.pages.home.banners.layout === 'multiple'">
                    <MultipleBannerSlider :primaryBanner="banners[2001]" :secondaryBanner="banners[2002]"/>
                </div>
            </div>
            <div class="banners-block">
                <OneColumnBanner :banners="banners[2008]"/>
                <TwoColumnsBanner :banners="banners[2009]"/>
                <ThreeColumnsBanner :banners="banners[2010]"/>
                <FourColumnsBanner :banners="banners[2011]"/>
            </div>
            <Showcases/>
            <div class="banners-block">
                <FourColumnsBanner :banners="banners[2020]" loading="lazy"/>
                <ThreeColumnsBanner :banners="banners[2019]" loading="lazy"/>
                <TwoColumnsBanner :banners="banners[2018]" loading="lazy"/>
                <OneColumnBanner :banners="banners[2017]" loading="lazy"/>
            </div>
            <ShowcasesSlider/>
            <ShowcasesContentSlider/>
            <div class="banners-block">
                <FourColumnsBanner :banners="banners[2016]" loading="lazy"/>
                <ThreeColumnsBanner :banners="banners[2015]" loading="lazy"/>
                <TwoColumnsBanner :banners="banners[2014]" loading="lazy"/>
                <OneColumnBanner :banners="banners[2013]" loading="lazy"/>
            </div>
            <UserReviews/>
            <BrandsSlider/>
            <SmallBlog/>
        </div>
        <WhatsAppButton class="btn-whatsapp-container sticky d-lg-none"/>
        <PreFooterInfo/>
    </div>
</template>

<script>
    import SingleBannerSlider from "~/components/SingleBannerSlider";
    import MultipleBannerSlider from "~/components/MultipleBannerSlider"
    import OneColumnBanner from "~/components/OneColumnBanner";
    import TwoColumnsBanner from "~/components/TwoColumnsBanner";
    import ThreeColumnsBanner from "~/components/ThreeColumnsBanner";
    import FourColumnsBanner from "~/components/FourColumnsBanner";
    import BannerSliderMobile from "~/components/BannerSliderMobile";
    import Showcases from "~/components/Showcases";
    import BrandsSlider from "~/components/BrandsSlider";
    import SmallBlog from "~/components/SmallBlog";
    import PreFooterInfo from '~/components/PreFooterInfo.vue';
    import WhatsAppButton from "~/components/WhatsAppButton";
    import ShowcasesSlider from "~/components/ShowcasesSlider";
    import ShowcasesContentSlider from "~/components/ShowcasesContentSlider";
    import UserReviews from "~/components/UserReviews.vue";

    export default {
        components: {
            ShowcasesContentSlider,
            ShowcasesSlider,
            SingleBannerSlider,
            MultipleBannerSlider,
            OneColumnBanner,
            TwoColumnsBanner,
            ThreeColumnsBanner,
            FourColumnsBanner,
            BannerSliderMobile,
            Showcases,
            BrandsSlider,
            SmallBlog,
            PreFooterInfo,
            WhatsAppButton,
            UserReviews
        },
        data() {
            return {
                banners: []
            }
        },
        async fetch() {
            const areas = [2001, 2002, 2008, 2009, 2010, 2011, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013]
            const thumbs = [255, 350, 540, 730, 1110, 1920]
            let url = `api/v1/banner/area/?envelope=true`
            areas.forEach(item => {
                url += '&area[]=' + item
            })
            thumbs.forEach(item => {
                url += `&thumb[${item}][x]=` + item
            })
            await this.$axios.$get(url).then((res) => {
                if (res.status === 'success' || res.status === 200) {
                    this.banners = res.data
                }
            }).catch((e) => {
                console.error(e)
            })
        }
    }
</script>
